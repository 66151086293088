
import { defineComponent, PropType } from 'vue'
import { CartEntry } from '@/types'
import { formatCurrency } from '@/utils/currency'

export default defineComponent({
  name: 'ProductList',
  props: {
    products: {
      type: Object as PropType<CartEntry[]>,
      required: true
    }
  },
  emits: ['remove'],
  setup () {
    const host = process.env.VUE_APP_HOST

    return {
      formatCurrency,
      host
    }
  }
})
