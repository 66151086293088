<template>
  <div class="hello">
    <h2>Bestellung</h2>

    <div class="details">
      <div class="other line">
        <div>
          <input type="text" v-model="additionalText" placeholder="Zusätzliche Produkte..."/>
        </div>
        <div class="second">
          <InputNumber v-model="additionalPrice" mode="currency" currency="CHF" locale="ch-DE" showButtons
                       buttonLayout="horizontal" onclick="this.setSelectionRange(0, this.value.length)" />
        </div>
      </div>
      <div class="brutto line">
        <div>Preis vor Rabatt</div>
        <div class="second">{{ formatCurrency(bruttoPrice) }}</div>
      </div>
      <div class="discount line">
        <div>
          Rabatt
          <InputNumber :max="100" :min="0" v-model="discount" showButtons buttonLayout="horizontal"/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <small>(<a @click="discount = 5">5%</a> / <a @click="discount = 10">10%</a> / <a
            @click="discount = 15">15%</a>)</small>
        </div>
        <div class="second">
          {{ formatCurrency(discountAmount) }}
        </div>
      </div>
      <div class="netto line">
        <div>Zu zahlen:</div>
        <div>{{ formatCurrency(nettoPrice) }}</div>
      </div>
      <div class="submit line">
        <button @click.prevent="order" class="button">Bestellung absenden</button>
      </div>
    </div>

    <div>
      Brutto: {{ bruttoPrice }}<br />
      Rabatt (ungerundet): {{ discountAmount }}<br />
      Netto: {{ nettoPrice }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, ComputedRef } from 'vue'
import { CartEntry } from '@/types'
import { format, formatCurrency } from '@/utils/currency'
import axios from 'axios'

export default defineComponent({
  name: 'Order',
  props: {
    products: {
      type: Object as PropType<CartEntry[]>,
      required: true
    }
  },
  emits: ['clear'],
  setup (props, { emit }) {
    const discount = ref<number>(0)
    const bruttoPrice = computed<number>(() => {
      let price = 0

      props.products.forEach(p => {
        price += (p.amount * p.product.price)
      })

      return price + (additionalPrice.value * 100)
    })

    const discountAmount = computed<number>(() => {
      return Math.round(Math.round(bruttoPrice.value * (discount.value / 100)) / 5) * 5
    })

    const nettoPrice: ComputedRef<number> = computed<number>(() => {
      return bruttoPrice.value - discountAmount.value
    })

    const additionalText = ref<string>('')
    const additionalPrice = ref<number>(0)

    async function order () {
      const response = await axios.post('/order', {
        products: props.products,
        additionalText: additionalText.value,
        additionalPrice: additionalPrice.value,
        netto: nettoPrice.value,
        discount: discountAmount.value,
        brutto: bruttoPrice.value
      })

      window.open(process.env.VUE_APP_HOST + '/admin/deliverynote/' + response.data.id)
      console.log(response)

      emit('clear')
      discount.value = 0
      additionalPrice.value = 0
      additionalText.value = ''
    }

    return {
      bruttoPrice,
      formatCurrency,
      discount,
      discountAmount,
      nettoPrice,
      additionalPrice,
      additionalText,
      order
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .p-button {
    width: 50px !important;
  }

  .details {
    padding: 20px 50px;
    border-top: 1px solid #eee;

    .line {
      font-size: 1.2em;
      display: flex;
      padding: 5px 0;
      border-bottom: 1px solid #eee;

      div {
        width: 50%;
        text-align: left;

        &:last-child {
          text-align: right;
        }
      }

      &.submit {
        display: block;
        text-align: right;

        .button {
          padding: 10px;
          background-color: #B3DCFF;
          cursor: pointer;
        }
      }

      &.discount {
        input {
          width: 30px;
          padding: 5px 5px;
        }

        small {
          font-size: 0.8em;

          a {
            text-decoration: underline;
            color: blue;
            cursor: pointer;
          }
        }
      }

      &.netto {
        font-weight: bold;
        font-size: 2em;
      }

      &.other {
        input {
          width: 100%;
          padding: 10px;

          &.p-inputtext {
            text-align: right;
            width: 80px;
          }
        }
      }
    }
  }
</style>
