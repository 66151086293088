<template>
  <div class="finder">
    <input type="text" v-model="searchText" v-on:keyup.enter="search" ref="searchField">
  </div>
  <ul class="preview" v-if="searchResult.length > 0">
    <li v-for="product in searchResult" :key="product.id" @click="addProduct(product)">
      <div>
        <img :src="host + product.image" v-if="product.image" />
        {{ product.name }}<br />
        {{ product.variant }}
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import { Product } from '@/types'

export default defineComponent({
  name: 'ProductFinder',
  props: {},
  emits: ['select'],
  setup (props, { emit }) {
    const host = process.env.VUE_APP_HOST
    const searchText = ref('')
    const searchField = ref<HTMLElement>()
    const searchResult = ref<Product[]>([])

    async function search () {
      searchByText(searchText.value)
      /* const barcode = parseInt(searchText.value)

      if (!isNaN(barcode)) {
        searchByBarcode(barcode)
      } else {
        searchByText(searchText.value)
      } */
    }

    onMounted(() => {
      if (searchField.value) {
        searchField.value.focus()
      }
    })

    async function searchByText (text: string) {
      const response = await axios.get('/bytext?text=' + text)

      console.log(response.data)
      searchText.value = ''
      if (response.data) {
        const foundProducts = response.data as Product[]

        if (foundProducts.length === 1 && !isNaN(parseInt(text))) {
          addProduct(foundProducts[0])
        } else {
          searchResult.value = response.data as Product[]
        }
      } else {
        alert('Produkt nicht gefunden!')
      }
    }
    async function searchByBarcode (barcode: number) {
      const response = await axios.get('/bybarcode?barcode=' + barcode)

      console.log(response.data)
      searchText.value = ''
      if (response.data) {
        addProduct(response.data as Product)
      } else {
        alert('Produkt nicht gefunden!')
      }
    }
    function addProduct (product: Product) {
      searchResult.value = []
      emit('select', product)
      searchText.value = ''

      if (searchField.value) {
        searchField.value.focus()
      }
    }

    return {
      searchText,
      search,
      searchField,
      searchResult,
      host,
      addProduct
    }
  }
})
</script>

<style scoped lang="scss">
input {
  width: 80%;
  padding: 10px 15px;
}

.preview {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;

  li {
    width: 33%;
    cursor: pointer;

    div {

      img {
        width: 100%;
      }

      background: #eee;
      border: 1px solid #aaa;
      margin: 5px;
      padding: 5px;
    }
  }
}
</style>
