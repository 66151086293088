
import { computed, defineComponent, PropType, ref, ComputedRef } from 'vue'
import { CartEntry } from '@/types'
import { format, formatCurrency } from '@/utils/currency'
import axios from 'axios'

export default defineComponent({
  name: 'Order',
  props: {
    products: {
      type: Object as PropType<CartEntry[]>,
      required: true
    }
  },
  emits: ['clear'],
  setup (props, { emit }) {
    const discount = ref<number>(0)
    const bruttoPrice = computed<number>(() => {
      let price = 0

      props.products.forEach(p => {
        price += (p.amount * p.product.price)
      })

      return price + (additionalPrice.value * 100)
    })

    const discountAmount = computed<number>(() => {
      return Math.round(Math.round(bruttoPrice.value * (discount.value / 100)) / 5) * 5
    })

    const nettoPrice: ComputedRef<number> = computed<number>(() => {
      return bruttoPrice.value - discountAmount.value
    })

    const additionalText = ref<string>('')
    const additionalPrice = ref<number>(0)

    async function order () {
      const response = await axios.post('/order', {
        products: props.products,
        additionalText: additionalText.value,
        additionalPrice: additionalPrice.value,
        netto: nettoPrice.value,
        discount: discountAmount.value,
        brutto: bruttoPrice.value
      })

      window.open(process.env.VUE_APP_HOST + '/admin/deliverynote/' + response.data.id)
      console.log(response)

      emit('clear')
      discount.value = 0
      additionalPrice.value = 0
      additionalText.value = ''
    }

    return {
      bruttoPrice,
      formatCurrency,
      discount,
      discountAmount,
      nettoPrice,
      additionalPrice,
      additionalText,
      order
    }
  }
})
